import React from 'react';
import NavigationBar from './components/NavigationBar';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <header>
          <NavigationBar/>
      </header>
      <section className='page home' id='home'>
          HOME
      </section>
      <section className="page blog" id="blog">
          BLOG
      </section>
      <section className="page project" id="project">
          PROJECT
      </section>
      <footer>
          <Contact/>
      </footer>
    </div>
  );
}

export default App;
