import { useRef } from 'react';
import './NavigationBar.css'

function NavigationBar() {

    const inputRef = useRef<HTMLInputElement>(null);

    const onClicked = () => {
        if (inputRef.current) {
            alert('search with value: ' + inputRef.current.value);
            inputRef.current.value = '';
        }
    }

    const submitSearch = () => {
        console.log("submited!") // will re-render --> console cannot see the message out.
    }

    return (
        <nav className='navBar'>
            <ul className='navTabs'>
                <li><a href='/' className='navTab-active'>Home</a></li>
                <li><a href='#blog'>Blog</a></li>
                <li><a href='#project'>Project</a></li>
                <li><a href='#contact'>Contact</a></li>
            </ul>
            <form className="navSearchBar" onSubmit={submitSearch}>
                <input ref={inputRef} className='navSearchInput' placeholder='search' type="text"/>
                <input className='navSearchBtn' type='submit' value='Go!' onClick={onClicked}/>
            </form>
        </nav>
    );
}

export default NavigationBar;